<style>
    .footer {
        padding: 0;
        height: 100% !important;
        background: #001529 no-repeat bottom center;
        background-size: 100% auto;
    }

    .wrap {
        margin: 0 auto;
        width: 1200px;
        min-width: 1200px;
    }

    .login-bg {
        width: 100%;
        height: 100vh !important;
        background: url(../assets/bg.png) ;
        background-size: 100% 100%;
    }

    .login-bg .ant-input,
    .login-bg .ant-input:focus {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #14909b !important;
        background: transparent;
        box-shadow: none !important;
        color: #14909b;
    }

    .login-card input::-webkit-input-placeholder {
        color: #14909b;
    }

    .login-bg .ant-input i {
        font-size: 22px;
        color: #14909b;
    }

    .login-card {
        background-size: 100% 100%;
        padding: 0px 0px;
    }

    .login-bg .has-error .ant-input-affix-wrapper .ant-input,
    .login-bg .has-error .ant-input-affix-wrapper .ant-input:hover {
        background: transparent;
    }

    .ant-btn-primary,
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        background-color: #14909b;
    }

    .login-bg .ant-input {
        padding-left: 40px !important;
    }
</style>
<template>
    <div style="background:#14909B;width:100%;height:100%">
        <a-layout class="login-bg">
            <a-layout-content style="padding:0px;">
                <a-row style="width:490px;margin:10% auto 0 auto;">
                    <a-col span="24">
                        <a-card style="background: rgba(0,0,0,0.65);border:0;overflow: visible;padding:50px;border-radius: 10px;">
                            <h1 style="font-weight:300;font-size:24px;margin-bottom:50px;text-align:left;color:#fff">
                                <img src="../assets/logo.png" width="120px" style="margin-right: 10px">健康中国服务平台
                            </h1>
                            <h1 style="font-weight:400;font-size:22px;margin-bottom:26px;text-align:left;color:#fff">
                                登录</h1>
                            <a-alert v-if="loginError" type="error" :message="loginErrorMessage" :closable="false"
                                     showIcon style="margin: 0 0 15px 0;"/>
                            <a-form-model class="login-card" ref="login_ref" :model="login_data" :rules="login_rules">
                                <a-form-model-item prop="name">
                                    <a-input type="text" v-model="login_data.name" placeholder="请输入手机号">
                                        <i class="iconfont icon-shoujihao" slot="prefix" style="color:#14909B;"/>
                                    </a-input>
                                </a-form-model-item>
                                <a-form-model-item prop="pwd">
                                    <a-input type="password" v-model="login_data.pwd" placeholder="请输入密码">
                                        <i class="iconfont icon-mima" slot="prefix" style="color:#14909B;"/>
                                    </a-input>
                                </a-form-model-item>
                            </a-form-model>
                            <a-button type="primary" round size="large" style="margin-top:30px;width:100%;border:0"
                                      @click="login('login_ref')">登 录
                            </a-button>
                        </a-card>
                    </a-col>
                </a-row>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import request from "../plugins/axios.js";

    export default {
        data() {
            return {
                //登录报错信息
                loginError: false,
                loginErrorMessage: "",
                //登录表单
                login_data: {
                    name: "",
                    pwd: "",
                },
                //登录表单验证规则
                login_rules: {
                    name: [{required: true, message: "请输入登录账号"}],
                    pwd: [{required: true, message: "请输入登录密码"}],
                },
            };
        },
        components: {},
        mounted() {
            //设置背景动画播放速度
            let obj = document.getElementById("bg").childNodes;
            obj[0].playbackRate = 0.75;
        },
        methods: {
            go_login() {
                this.$router.push("login");
            },
            login(form_ref) {
                this.$refs[form_ref].validate((vaild, fields) => {
                    if (vaild) {
                        //提交表单
                        this.loginError = false;
                        this.loginErrorMessage = "";
                        request({
                            url: "/hospital/Login/index",
                            data: {
                                mobile: this.login_data.name,
                                pwd: this.login_data.pwd,
                                hp: 2000
                            },
                        }).then(
                            (ret) => {
                             if (ret.code == 1) {
                                    localStorage.setItem('ua', JSON.stringify(ret.data))
                                    window.localStorage.setItem("hrole", ret.data.role);
                                    window.localStorage.setItem("token", ret.data.token);
                                    window.localStorage.setItem("name", ret.data.name);
                                    window.localStorage.setItem("head", ret.data.head);
                                    let menu = ret.data.menu
                                    let devices = false;
                                    menu.forEach(function (item) {
                                        if (item.url == '/admin/users') {
                                            devices = true;
                                        }
                                    })
                                    if (devices) {
                                        this.$router.push("/admin/users");
                                    } else {
                                        this.$router.push("/admin/device");
                                    }

                                    return true;
                                } else {
                                    this.loginError = true;
                                    this.loginErrorMessage = ret.msg;
                                }
                            },
                            (err) => {
                                this.loginError = true;
                                this.loginErrorMessage = err;
                            }
                        );
                    }
                    return false;
                });
            },
        },
    };
</script>
